.App {
  text-align: center;
  background-color: #fffffff7;
  background-size: cover;
  transition: all 0.5s ease-in-out;
  font-family: Arial, sans-serif;
  margin: auto;
  padding: 0 10%;
  position: relative;
}

.inverted {
  filter: invert(1);
  transition: all 0.5s ease-in-out;
}

.invert-background {
  background-color: black;
  transition: all 0.5s ease-in-out;
}


@keyframes fadeIn {
  0% {
      opacity: 0; /* Start fully transparent */
  }
  100% {
      opacity: 1; /* End fully opaque */
  }
}

@keyframes fadeInTwo {
  0% {
      opacity: 0; /* Start fully transparent */
  }
  100% {
      opacity: 0.3; /* End fully opaque */
  }
}
/* Apply the animation to the element */
.fade-in-one {
  animation: fadeIn 1s ease-in-out; /* 1s duration, easing function */
}

.fade-in-two {
  animation: fadeInTwo 3s ease-in-out; /* 3s duration, easing function */
}

.over-layer {
  z-index: 2;
  position: relative;
  transition: all 0.5s ease-in-out;
}

.under-layer {
  height: 500px;
  transition: all 0.5s ease-in-out;
  width: 500px;
  transform: translateX(-50%);
  left: 50%;
  top: 250px;
  background-size: cover;
  z-index: 1;
  position: fixed;
  mask-image: radial-gradient(closest-side, rgb(255, 255, 255) 60%, transparent 100%);
  /* mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1.0) 50%, transparent 100%); */
}

.bg-toggle {
  position: absolute;
  border: 1px solid black;
  cursor: pointer;
}

.background {
  margin: auto;
  height: 100%;
  width: 100%;
  background: url('./images/albumArt.jpg');
  background-size: cover;
  opacity: 50%;
  background-position: center;
}

.full-page-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: url('./images/background.jpg');
  background-size: cover;
  background-position: 50% 50%;
  opacity: 30%;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-bottom: 30px;
  transition: all 0.5s ease-in-out;
}

.main-logo {
  width: 100%;
  transform: scale(-1, 1);
  transition: all 0.5s ease-in-out;
  max-width: 500px;
  height: auto;
  margin: 0 auto;

  &:hover {
    cursor: pointer;
  }
}

.inverted-logo {
  transform: scale(1, 1);
  transform: 0;
}

.body {
  width: 100%;
  display: inline-block;
  padding-top: 50px;
}

.section-container {
  display: flex;
  width: 100%;
  margin: auto;
}

.content-block {
  position: relative;
  padding: 20px;
  margin-bottom: 40px;
  width: 100%;
}

.content-container {
  text-align: center;
  padding: 20px;
  height: 100%;
}

.link-section-container {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.icon {
  /* position: absolute; */
  margin: 0 15px;
  width: 50px;  /* Set the desired size */
  height: 50px; /* Set the desired size */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #ffdddd;
    cursor: pointer;
  }
}

.dropdown-container {
  position: relative;
  display: inline-block;
}

.dropdown-menu {
  position: absolute;
  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  border-radius: 10px;
  left: 10px;
  z-index: 3;
  /* display: none; */
  opacity: 0;
  transition: opacity 0.5s ease, visibility 0s linear 0s;  /* Instant visibility change after fade-in */

}

.dropdown-menu a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  border-radius: 10px;
  transition: background-color 0.2s ease, visibility 0s linear 0s;  /* Instant visibility change after fade-in */
}

.dropdown-menu a:hover {
  background-color: #ffdddd;
}

.dropdown-menu.show {
  opacity: 1;            /* Make it visible */
  visibility: visible;   /* Ensure it's rendered */
  transition: opacity 0.5s ease, visibility 0s linear 0s;  /* Instant visibility change after fade-in */
}

.caret {
  padding-left: 10px;
}

.site-link {
  /* position: absolute; */
  padding: 10px;
  margin: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 5px;
  color: black;
  background-repeat: no-repeat;
  background-size: 25px;
  background-position: center;
  transition: all 0.2s ease-in-out;
  width: 120px;

  a {
    text-decoration: none;
    color: black;
  }

  &:hover {
    background-color: #ffdddd;
    cursor: pointer;
  }
}

.contact-wrapper {
  width: 80%;
  margin: auto;
  padding: 30px 0;
}

.contact-row {
  display: flex;
  flex-flow: row wrap;
  margin: 10px 0;
}

.contact-label {
  width: 50%;
  text-align: left;
}

.contact-info {
  width: 50%;
  text-align: right;
}

.contact-info a {
  margin-bottom: 10px;
  text-decoration: none;
  color: black;
  display: block;
}

.contact-info a:hover {
  text-decoration: underline;
}

.popup-content {
  width: 60%;
  border-radius: 10px;
  padding: 10px;
}

.contact-inverted {
  background-color: black;
  color: white;
}


.close {
  position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 5px;
    border: 0;

    &:hover {
      cursor: pointer;
    }
}

.contact {
  display: block;
  margin: auto;
}

@media (max-width: 768px) {
  /* .fade-in-two {
    animation: none;
  } */

  .content-block {
    padding: 0;
  }

  .under-layer {
    height: 300px;
    width: 300px;
    opacity: 60%;
  }

  .site-link {
    width: 90px;
    font-size: 14px;

    &:hover {
      background-color: white;
      cursor: pointer;
    }
  }

  .icon {
    margin: 0;
    width: 43px;
    height: 43px;
  }

  .contact-row {
    display: block;
    text-align: center;
    margin-bottom: 20px;
  }

  .popup-content {
    width: 90%;
  }

  .contact-label {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
    text-decoration: underline;
    font-size: 20px;
  }

  .contact-info {
    width: 100%;
    text-align: center;
  }
}